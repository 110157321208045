import OpenAI from 'openai';

export class ImageProcessingService {
  static async processImages(files) {
    const responses = [];
    
    try {
      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY?.trim(),
        dangerouslyAllowBrowser: true
      });

      for (const file of files) {
        try {
          console.log('Processing file:', file.name);
          const base64Image = await this.fileToBase64(file);
          console.log('Image converted to base64');
          
          const response = await openai.chat.completions.create({
            model: "gpt-4o",
            messages: [
              {
                role: "system",
                content: "You are a helpful assistant that analyzes images and provides information about items in JSON format. Always respond with a JSON object containing an 'items' array with objects having 'name', 'model', 'price', and 'source' fields. If you cannot find the exact item, find a similar replacement. Focus on getting accurate pricing for the items."
              },
              {
                role: "user",
                content: [
                  
                  {
                    type: "image_url",
                    image_url: {
                      url: `data:image/jpeg;base64,${base64Image}`
                    }
                  }
                ]
              }
            ],
            max_tokens: 4096,
            temperature: 0.52,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
            response_format: { type: "json_object" }  // Force JSON response
          });

          console.log('OpenAI Response:', response);
          responses.push(response.choices[0].message.content);
          
        } catch (error) {
          console.error('Detailed error:', {
            message: error.message,
            status: error.status,
            response: error.response,
            stack: error.stack
          });
          
          responses.push(JSON.stringify({
            items: [{
              name: "Error processing image",
              model: "N/A",
              price: "$0.00",
              source: "N/A"
            }]
          }));
        }
      }
    } catch (error) {
      console.error('OpenAI initialization error:', error);
      throw error;
    }
    
    return responses;
  }

  static fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  static formatResults(responses) {
    let allItems = [];
    
    for (const response of responses) {
      try {
        console.log('Formatting response:', response);
        // Parse the JSON string
        const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response;
        console.log('Parsed response:', parsedResponse);
        
        // Extract items array
        if (parsedResponse && parsedResponse.items && Array.isArray(parsedResponse.items)) {
          const formattedItems = parsedResponse.items.map(item => ({
            name: item.name || "Unknown Item",
            brand: "Auto-detected",
            model: item.model || "N/A",
            price: this.parsePrice(item.price),
            condition: "Assessed",
            website: item.source || "N/A"
          }));
          allItems = [...allItems, ...formattedItems];
        }
      } catch (error) {
        console.error('Error parsing response:', error);
        console.log('Problematic response:', response);
        allItems.push({
          name: "Error processing image",
          brand: "Auto-detected",
          model: "N/A",
          price: 0,
          condition: "Assessed",
          website: "N/A"
        });
      }
    }
    
    return allItems;
  }

  static parsePrice(priceString) {
    if (!priceString) return 0;
    // Remove currency symbol and commas, then convert to number
    const numericPrice = parseFloat(priceString.replace(/[$,]/g, ''));
    return isNaN(numericPrice) ? 0 : numericPrice;
  }
}