import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthContext';

// Debug environment variables
console.log('Environment Variables at Startup:');
console.log('NODE_ENV:', process.env.NODE_ENV);
console.log('OpenAI Key exists:', !!process.env.REACT_APP_OPENAI_API_KEY);
console.log('OpenAI Key prefix:', process.env.REACT_APP_OPENAI_API_KEY?.substring(0, 7));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);