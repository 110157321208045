import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

export class ReportService {
  static async generatePDFReport(analysisResults, uploadedFiles) {
    const doc = new jsPDF();
    
    this.addHeader(doc, analysisResults);
    this.addItemsTable(doc, analysisResults);
    await this.addPhotos(doc, uploadedFiles);
    this.addPageNumbers(doc);

    // Save the PDF
    doc.save(`contents-claim-report-${new Date().toISOString().split('T')[0]}.pdf`);
  }

  static async generateExcelReport(analysisResults, uploadedFiles) {
    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    
    // Prepare data for the main sheet
    const mainSheetData = [
      // Header row
      ['Item', 'Brand', 'Model', 'Condition', 'Source', 'Price'],
      // Data rows
      ...analysisResults.items.map(item => [
        item.name,
        item.brand,
        item.model,
        item.condition,
        item.website,
        typeof item.price === 'number' ? item.price : 0
      ]),
      // Empty row
      [],
      // Total row
      ['Total', '', '', '', '', analysisResults.totalValue]
    ];

    // Create the main worksheet
    const ws = XLSX.utils.aoa_to_sheet(mainSheetData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Claim Items');

    // Create summary sheet
    const summaryData = [
      ['Contents Claim Summary'],
      [],
      ['Generated Date', new Date().toLocaleDateString()],
      ['Total Items', analysisResults.items.length],
      ['Total Value', analysisResults.totalValue]
    ];

    const summaryWS = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(wb, summaryWS, 'Summary');

    // Save the Excel file
    XLSX.writeFile(wb, `contents-claim-report-${new Date().toISOString().split('T')[0]}.xlsx`);
  }

  static addHeader(doc, analysisResults) {
    doc.setFontSize(20);
    doc.text('Contents Claim Report', 20, 20);
    
    doc.setFontSize(12);
    doc.text(`Generated on ${new Date().toLocaleDateString()}`, 20, 30);
    doc.text(`Total Items: ${analysisResults.items.length}`, 20, 40);
    doc.text(`Total Value: $${analysisResults.totalValue.toFixed(2)}`, 20, 50);
  }

  static addItemsTable(doc, analysisResults) {
    const tableData = analysisResults.items.map(item => [
      item.name,
      item.brand,
      item.model,
      item.condition,
      item.website,
      `$${typeof item.price === 'number' ? item.price.toFixed(2) : '0.00'}`
    ]);

    doc.autoTable({
      startY: 60,
      head: [['Item', 'Brand', 'Model', 'Condition', 'Source', 'Price']],
      body: tableData,
      theme: 'grid',
      headStyles: {
        fillColor: [66, 139, 202],
        textColor: 255,
        fontSize: 10
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      }
    });
  }

  static async addPhotos(doc, uploadedFiles) {
    if (uploadedFiles.length === 0) return;

    doc.addPage();
    doc.setFontSize(16);
    doc.text('Uploaded Photos', 20, 20);

    const margin = 20;
    const pageWidth = doc.internal.pageSize.width - 2 * margin;
    const photosPerRow = 2;
    const photoWidth = pageWidth / photosPerRow;
    const photoHeight = photoWidth * 0.75;
    const spacing = 10;

    let currentY = 40;
    let currentX = margin;
    
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      
      if (currentY + photoHeight > doc.internal.pageSize.height - margin) {
        doc.addPage();
        currentY = margin + 20;
      }

      try {
        const imgUrl = URL.createObjectURL(file);
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = imgUrl;
        });

        doc.addImage(
          img,
          'JPEG',
          currentX,
          currentY,
          photoWidth - spacing,
          photoHeight - spacing
        );

        URL.revokeObjectURL(imgUrl);

        if ((i + 1) % photosPerRow === 0) {
          currentX = margin;
          currentY += photoHeight;
        } else {
          currentX += photoWidth;
        }
      } catch (error) {
        console.error('Error adding photo to PDF:', error);
      }
    }
  }

  static addPageNumbers(doc) {
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 10,
        { align: 'center' }
      );
    }
  }
}