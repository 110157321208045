import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import DemoPage from './pages/DemoPage';
import TablePage from './pages/TablePage';
import Program from './components/Program';
import LoginPage from './pages/LoginPage';
import { useAuth } from './context/AuthContext';

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  const { user } = useAuth();
  
  // Define which routes should have padding-top for the fixed navbar
  const routesWithPadding = ['/program']; // Add routes that need padding here

  const getContentClass = (pathname) => {
    const baseClasses = "flex-grow";
    return routesWithPadding.includes(pathname) 
      ? `${baseClasses} pt-16` // Add padding for fixed navbar
      : baseClasses;
  };

  return (
    <Router>
      <div className="min-h-screen flex flex-col bg-white text-black font-sans">
        {/* Conditionally render Navbar based on route */}
        <Routes>
          <Route path="/program" element={<Navbar variant="minimal" />} />
          <Route path="/login" element={null} /> {/* No navbar on login page */}
          <Route path="*" element={<Navbar />} />
        </Routes>

        {/* Main content with conditional padding */}
        <main className={getContentClass(window.location.pathname)}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/demo" element={<DemoPage />} />
            <Route path="/table" element={<TablePage />} />
            <Route 
              path="/login" 
              element={user ? <Navigate to="/program" replace /> : <LoginPage />} 
            />
            <Route
              path="/program"
              element={
                <ProtectedRoute>
                  <Program />
                </ProtectedRoute>
              }
            />
          </Routes>
        </main>

        {/* Conditionally render Footer based on route */}
        <Routes>
          <Route path="/program" element={null} /> {/* No footer on program page */}
          <Route path="/login" element={null} /> {/* No footer on login page */}
          <Route path="*" element={<Footer />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;