import React from 'react';
import { FileText, FileSpreadsheet, X } from 'lucide-react';

const ReportOptionsModal = ({ isOpen, onClose, onGenerateReport }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X className="w-5 h-5" />
        </button>

        <h2 className="text-xl font-semibold mb-4">Generate Report</h2>
        <p className="text-gray-600 mb-6">Choose your preferred format:</p>

        <div className="space-y-4">
          <button
            onClick={() => {
              onGenerateReport('pdf');
              onClose();
            }}
            className="w-full flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition-colors group"
          >
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-red-100 rounded-lg group-hover:bg-red-200 transition-colors">
                <FileText className="w-6 h-6 text-red-600" />
              </div>
              <div className="text-left">
                <div className="font-medium">PDF Format</div>
                <div className="text-sm text-gray-500">Professional document format</div>
              </div>
            </div>
          </button>

          <button
            onClick={() => {
              onGenerateReport('excel');
              onClose();
            }}
            className="w-full flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition-colors group"
          >
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-green-100 rounded-lg group-hover:bg-green-200 transition-colors">
                <FileSpreadsheet className="w-6 h-6 text-green-600" />
              </div>
              <div className="text-left">
                <div className="font-medium">Excel Format</div>
                <div className="text-sm text-gray-500">Spreadsheet for data analysis</div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportOptionsModal;